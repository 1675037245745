<template>
  <div class="categoryTag">
    <p class="categoryTagName">
      {{ getCat.title ? getCat.title.toUpperCase() : getCat.toUpperCase() }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: [Object, String]
  },
  components: {},
  data: () => ({}),
  computed: {
    getCat: function() {
      if (this.data.title) {
        return this.data;
      }
      return this.data;
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.categoryTag {
  border-radius: 5px;
  background-color: #00b4ea;
  padding: 0px 10px;
  height: 23px;
  width: 80px;
  width: auto;
  position: absolute;
}
p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.categoryTagName {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.9px;
  line-height: 25px;
  color: #202020;
}
</style>
