<template>
  <div @click="goTo()" class="newsCard">
    <div class="newsCardImgContainer">
      <img
        v-if="returner.field_media[0] && returner.field_media[0].image"
        class="newsCardImg"
        :src="returner.field_media[0].image.url"
        alt=""
      />
      <img
        v-else
        class="newsCardImg"
        src="../assets/golf-fallback.jpg"
        alt=""
      />
      <div
        class="videoTag"
        v-if="returner.field_media[0] && returner.field_media[0].video"
      >
        <img class="videoTagIcon" src="../assets/play.svg" alt="Afspil" />
      </div>
      <Tag class="tag" :data="returner.field_category" />
    </div>
    <div class="content">
      <p class="newsCardDate">{{ returner.created }}</p>
      <h2 v-html="returner.title"></h2>
    </div>
    <!-- <p class="newsCardSubtitle" v-html="returner.field_manchet"></p> -->
  </div>
</template>

<script>
import Tag from "../components/CategoryTag.vue";

export default {
  props: {
    data: [Object, Array],
  },
  components: {
    Tag,
  },
  data: () => ({}),
  computed: {
    returner: function () {
      return this.data;
    },
  },
  methods: {
    goTo: function () {
      window.open(
        `https://www.golf.dk${this.returner.alias}?id=${this.returner.nid}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.newsCard {
  cursor: pointer;
  padding: 10px;
  flex: 1 1 auto;
  overflow-wrap: anywhere;
  text-align: left;
  max-width: 50%;
  min-width: 350px;
  display: flex;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    margin: auto;
  }

  &:hover {
    .newsCardImg {
      transform: scale(1.1);
    }
  }
}

.mobile .newsCard {
  display: none;
}

.newsCardImgContainer {
  position: relative;
  overflow: hidden;
  max-width: 50%;
  height: 150px;
  border-radius: 10px;
}
.newsCardImg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 150px;
  transition: all 0.5s ease-in-out;
}
.tag {
  bottom: 2%;
  left: 2%;
}
.fromSlider .newsCardImg {
  height: 225px;
}
.newsCardDate {
  color: #444444;
  font-family: Raleway;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
}
.content {
  width: 50%;
  box-sizing: border-box;
  margin-left: 20px;
}
h2 {
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.newsCardSubtitle {
  line-height: 16px;
  line-height: 24px;
  font-weight: 400;
}
.videoTag {
  border-radius: 5px;
  padding: 0px 10px;
  position: absolute;
  top: 0px;
  right: -12px;
}
.videoTagIcon {
  width: 60px;
}
</style>
