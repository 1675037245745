<template>
  <div class="WIWT" @click="goTo">
    <div class="WIWTImgContainer">
      <img
        v-if="
          (returner.field_media[0] && returner.field_media[0].image) ||
          returner.field_media.image
        "
        class="WIWTImg"
        :src="
          returner.field_media[0].image
            ? returner.field_media[0].image.url
            : returner.field_media.image.url
        "
        alt=""
      />
      <img v-else class="WIWTImg" src="../assets/golf-fallback.jpg" alt="" />
    </div>
    <Tag class="tag" :data="returner.field_category" />
    <div class="textOnImg">
      <h2 v-html="returner.title"></h2>
      <!-- <h5 v-html="returner.field_manchet"></h5> -->
    </div>
  </div>
</template>

<script>
import Tag from "../components/CategoryTag.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    Tag,
  },
  data: () => ({}),
  computed: {
    returner: function () {
      return this.data;
    },
  },
  methods: {
    goTo: function () {
      window.open(
        `https://www.golf.dk${this.returner.alias}?id=${this.returner.nid}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.WIWT {
  box-sizing: border-box;
  padding: 10px;
  height: 250px;
  position: relative;
  min-width: 390px;
  flex: 2 1 0;
  overflow-wrap: anywhere;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    .WIWTImg {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 390px;
    padding: 0px;
    height: auto;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 0px;
  }
}
.WIWTImgContainer {
  border-radius: 8px;
  overflow: hidden;
  height: 240px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 250px;
  }
}
.WIWTImg {
  width: auto;
  height: auto;
  min-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  object-fit: cover;
  object-position: top;

  @media screen and (max-width: 768px) {
    max-height: auto;
  }
}
.textOnImg {
  position: relative;
  bottom: 40%;
  color: white;
  margin: 0px auto;
  text-align: center;
  width: 80%;
  text-shadow: 2px 2px 3px #000000;

  @media screen and (max-width: 768px) {
    position: initial;
    color: black;
    width: 100%;
  }
}
.tag {
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
}
h2 {
  font-size: 20px;
  margin-block-end: 0.5em;
  line-height: 28px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 1.7em;
    text-align: center;
    text-shadow: none;
  }
}
h5 {
  font-weight: 400;
  width: auto;
  font-size: 16px;
  line-height: 24px;
  margin: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
