<template>
  <div class="box">
    <div class="topHeader"><strong>GOLF.dk</strong> NYHEDER</div>
    <div class="content" v-if="data1 && data2">
      <div class="teaser">
        <WIWT v-for="card in data1" :key="card.title" :data="card" />
      </div>
      <div class="card">
        <NewsCard v-for="card in data2" :key="card.title" :data="card" />
      </div>
    </div>
    <div class="botHeader">Nyheder leveret af <strong>GOLF.dk</strong></div>
  </div>
</template>

<script>
import NewsCard from "../components/NewsCard2";
import WIWT from "../components/WideImageWithText";
// @ is an alias to /src

export default {
  name: "Box",
  components: {
    NewsCard,
    WIWT,
  },
  data: () => ({
    data1: null,
    data2: null,
  }),
  mounted() {
    this.$store
      .dispatch("FETCH_DYNAMIC_LIST", {
        listType: "latest",
        offset: 0,
        itemsPerPage: 8,
        page: 0,
      })
      .then((response) => {
        console.log("response", response);
        this.data1 = response.slice(0, 2);
        this.data2 = response.slice(2);
        console.log("response sl", response.slice(0, 2));
        console.log("data", this.data1, this.data2);
      })
      .catch((error) => {
        console.log("error getting list", error);
      });
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.box {
  margin: 10px 0px;

  .content {
    .teaser {
      display: flex;
      flex-wrap: wrap;
    }
    .card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .topHeader {
    width: 100%;
    border-bottom: 1px solid #346243;
    margin: 20px 0px;
    text-align: left;
  }
  .botHeader {
    width: 100%;
    border-top: 1px solid #346243;
    text-align: right;
  }
  strong {
    color: #346243;
  }
}
</style>
