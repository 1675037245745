import { createRouter, createWebHistory } from "vue-router";
import Grid from "../views/Grid.vue";
import Box from "../views/Box.vue";
import Sidebar from "../views/Sidebar.vue";

const routes = [
  {
    path: "/grid",
    name: "Grid",
    component: Grid,
  },
  {
    path: "/box",
    name: "Box",
    component: Box,
  },
  {
    path: "/sidebar",
    name: "Sidebar",
    component: Sidebar,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
