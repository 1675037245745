/* eslint-disable no-empty-pattern */
import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {},
  mutations: {},
  actions: {
    async FETCH_DYNAMIC_LIST({}, { listType, offset, itemsPerPage, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_GATEWAY}/api/v1/drupal/dynamiclist/${listType}/${offset}/${itemsPerPage}/${page}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
  modules: {},
});
