<template>
  <div class="box">
    <div class="topHeader"><strong>GOLF.dk</strong> NYHEDER</div>
    <div class="content" v-if="data">
      <NewsCard v-for="card in data" :key="card.title" :data="card" />
    </div>
    <div class="botHeader">Nyheder leveret af <strong>GOLF.dk</strong></div>
  </div>
</template>

<script>
import NewsCard from "../components/NewsCard";
// @ is an alias to /src

export default {
  name: "Box",
  components: {
    NewsCard,
  },
  data: () => ({
    data: null,
  }),
  mounted() {
    this.$store
      .dispatch("FETCH_DYNAMIC_LIST", {
        listType: "latest",
        offset: 0,
        itemsPerPage: 4,
        page: 0,
      })
      .then((response) => {
        console.log("response", response);
        this.data = response;
      })
      .catch((error) => {
        console.log("error getting list", error);
      });
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.box {
  margin: 10px 0px;
  justify-content: space-around;

  .content {
    display: flex;
  }

  .topHeader {
    width: 100%;
    border-bottom: 1px solid #346243;
    margin: 20px 0px;
    text-align: left;
  }
  .botHeader {
    width: 100%;
    border-top: 1px solid #346243;
    text-align: right;
  }
  strong {
    color: #346243;
  }
}
</style>
